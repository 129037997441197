import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";

const LoadingScreen = () => {
  return (
    <Box>
      <CircularProgress></CircularProgress>
    </Box>
  );
};

export default LoadingScreen;
