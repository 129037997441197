import { LocalActivity, Share } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Link,
  Typography,
} from "@mui/material";
import MobileDetect from "mobile-detect";
import React from "react";
import { useLocation } from "react-router-dom";
import { OrderInfo } from "../types";
import { EventContext } from "../shared:0.0.2/frame/EventContextProvider";
export const OrderSummary = () => {
  let md = new MobileDetect(window.navigator.userAgent);
  const [isMobile, setIsMobile] = React.useState<boolean>(
    md.os() === "iOS" || md.os() === "AndroidOS"
  );

  const location = useLocation();
  let orderInfo: OrderInfo = location.state;
  const { event, setEvent } = React.useContext(EventContext)!;

  if (!event) {
    return <CircularProgress></CircularProgress>;
  }

  function downloadImage(index: number) {
    fetch(
      process.env.REACT_APP_PILOT_BE_URL +
        "/v0/tickets/pdf?paypalId=" +
        orderInfo.orderId +
        "&ticketNumber=" +
        index,
      {
        method: "POST",
        headers: { "Content-Type": "application/pdf" },
      }
    ).then((response) => {
      //TODO: i don t know what i am doing haha
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.target = "_blank";
        a.download = "ticket" + index + ".pdf";
        a.click();
      });
    });
  }
  //TODO
  const download = (id: number) => {
    fetch(
      process.env.REACT_APP_STATIC_FILES_SERVER +
        "/" +
        event.contractAddress +
        "/images/" +
        id +
        ".png",
      {
        method: "GET",
        headers: {},
      }
    )
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", id + ".png"); //or any other extension
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        //TODO: enter meaningful something
      });
  };

  return (
    <Box sx={{ p: { xs: 2, md: 10 }, pv: { xs: 4, md: 10 } }}>
      <Typography
        sx={{ typography: { xs: "h4", md: "h2" } }}
        textAlign={"center"}
      >
        Thank you for your order for {event.title}!
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mt: 4,
        }}
      >
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
          }}
        >
          <LocalActivity sx={{ color: "primary.main" }}></LocalActivity>
          <Typography ml={2}>
            {orderInfo.quantity}x {orderInfo.ticketType}
          </Typography>
        </Box>
        <Typography mt={3} mb={1}>
          The tickets have been send to you via email, please also check your
          spam folder or download the ticket here.
        </Typography>{" "}
        <Typography mb={1}>
          With your Ticket you get access to the Digital Backstage of{" "}
          {event.title}. Follow the link for Photoes, DJ-Sets recorded during
          the night & more exclusive content:
        </Typography>
        {Array(orderInfo.quantity)
          .fill(null)
          .map((_, ind) => {
            return (
              <Box key={"ticketdownload" + ind} sx={{ mt: 1 }}>
                <Button
                  sx={{ p: 1 }}
                  variant="contained"
                  onClick={() => downloadImage(ind)}
                >
                  download ticket {ind + 1}
                </Button>
                <Button
                  sx={{
                    color: "white",
                    backgroundColor: "secondary.main",
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    "&:hover": {
                      background: "#395437", // <- add here your desired color, for demonstration purposes I chose red
                    },
                  }}
                  variant="contained"
                  href={orderInfo.verifyLinks[ind]}
                  target={"_blank"}
                >
                  Your Digital Backstage
                </Button>
              </Box>
            );
          })}
        {event.ticketType === "UNIQUE" ? (
          <Typography sx={{ mt: 2 }}>
            Your digital Backstage{orderInfo.verifyLinks.length > 1 ? "s" : ""}{" "}
            contain
            {orderInfo.verifyLinks.length > 1 ? " " : "s "}an Artwork designed
            by <Link href={event.designer.link}>{event.designer.name}</Link> and{" "}
            <Link href={event.socials[0]?.link}>{event.organizer}</Link>:
          </Typography>
        ) : null}
        {event.ticketType === "UNIQUE"
          ? Array(orderInfo.quantity)
              .fill(null)
              .map((_, ind) => {
                let imgInd = orderInfo.cvtIds[ind];
                return (
                  <Box
                    key={ind}
                    sx={{
                      mt: 2,
                      display: "flex",
                      flexDirection: "column-reverse",
                    }}
                  >
                    {isMobile ? (
                      <Button
                        variant="contained"
                        onClick={() => {
                          fetch(
                            process.env.REACT_APP_STATIC_FILES_SERVER +
                              "/" +
                              event.contractAddress +
                              "/images/" +
                              imgInd +
                              ".png",
                            {
                              method: "GET",
                            }
                          )
                            .then((response) => response.blob())
                            .then((blob) => {
                              let file = new File([blob], "artwork.png");
                              navigator.share({ files: [file] });
                            });
                        }}
                        sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                      >
                        <Share></Share>
                        <Typography ml={2}>Share</Typography>
                      </Button>
                    ) : null}
                    <ButtonBase
                      onClick={() => download(imgInd)}
                      key={"artwork" + ind}
                    >
                      <Box
                        component={"img"}
                        src={
                          process.env.REACT_APP_STATIC_FILES_SERVER +
                          "/" +
                          event.contractAddress +
                          "/images/" +
                          imgInd +
                          ".png"
                        }
                        alt={"artwork" + imgInd}
                        sx={{
                          width: "100%",
                          maxWidth: 400,
                        }}
                      ></Box>
                    </ButtonBase>
                  </Box>
                );
              })
          : null}
      </Box>
    </Box>
  );
};
