import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Quantity, Ticket } from "../../types";

type Props = {
  ticket: Ticket;
  setQuantity: (quantity: Quantity) => void;
  currChar: string;
};

export const TicketCell = ({ currChar, ticket, setQuantity }: Props) => {
  const handleChange = (event: SelectChangeEvent) => {
    setQuantity({ id: ticket.id, quantity: +event.target.value });
  };

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <TableRow
      key={"data-row-" + ticket.id}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell
        key={"name" + ticket.id}
        sx={{ fontWeight: "bold" }}
        component="th"
        scope="row"
      >
        <Typography fontWeight="bold">{ticket.name}</Typography>
        {ticket.description ? (
          <Typography maxWidth={400}>+ {ticket.description}</Typography>
        ) : null}
      </TableCell>
      <TableCell key={"price" + ticket.id} align="right">
        <Typography>{ticket.price + currChar}</Typography>
      </TableCell>
      <TableCell key={"quantity" + ticket.id} align="right">
        <Select
          value={"" + ticket.quantity}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          {Array.from({ length: 6 }, (_, i) => {
            return (
              <MenuItem key={i} value={i}>
                {"" + i}
              </MenuItem>
            );
          })}
        </Select>
      </TableCell>
      {!isXs ? (
        <TableCell key={"total-price" + ticket.id} align="right">
          <Typography>{ticket.quantity * ticket.price + currChar}</Typography>
        </TableCell>
      ) : null}
    </TableRow>
  );
};
