import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import {
  PayPalButtons,
  PayPalButtonsComponentProps,
  PayPalScriptProvider,
} from "@paypal/react-paypal-js";
import {
  CreateOrderActions,
  OnClickActions,
  OnCancelledActions,
  CreateOrderData,
} from "@paypal/paypal-js";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  FreeOrderResponse,
  OrderInfo,
  OrderResponse,
  Quantity,
  Ticket,
  TicketCountResponse,
} from "../../types";
import { EventData, EventState } from "../../shared:0.0.2/types/dto";
import LoadingScreen from "../../util/LoadingScreen";
import { NFTDisplay } from "./NFTDisplay";
import { TicketTable } from "./TIcketTable";

type Props = {
  event: EventData;
};

export const TicketShop = ({ event }: Props) => {
  const [loading, setLoading] = React.useState(false);

  //TODO: clean this up
  const ticket: Ticket[] = [
    {
      id: 1,
      name: "Regular Ticket",
      price: event.price,
      currency: event.currencyCode,
      description: event.ticketDescription,
      quantity: 1,
    },
  ];
  //we need all the refs to update the state in the paypalscriptprovider
  //https://stackoverflow.com/questions/69069357/paypal-button-cannot-read-new-react-state-how-to-work-with-dynamic-values-and-p
  const [tickets, setTickets] = React.useState<Ticket[]>(ticket);
  const hiddenEmailRef = React.useRef(true);
  const [email, setEmail] = React.useState<string>("");
  const emailRef = React.useRef("");
  const useEmailRef = React.useRef(true);
  const [usePaypalEmail, setUsePaypalEmail] = React.useState<boolean>(true);
  const [ticketsAvailable, setTicketsAvailable] = React.useState<String>("");
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");

  function isValidEmail(_email: string) {
    return /\S+@\S+\.\S+/.test(_email);
  }

  React.useEffect(() => {
    if (event && event.price === 0) {
      setUsePaypalEmail(false);
    }
  }, [event]);

  React.useEffect(() => {
    fetch(
      process.env.REACT_APP_PILOT_BE_URL +
        "/v0/payment/ticketCount?smartContractAddress=" +
        event.contractAddress,
      {
        method: "POST",
      }
    )
      .then((res) => {
        if (res.status !== 200) {
          //TODO: we need a base error Page
        }
        return res.json();
      })
      .then((ticketCountResponse: TicketCountResponse) => {
        console.log(ticketCountResponse);
        console.log(EventState[ticketCountResponse.eventState]);
        console.log(EventState["ACTIVE"]);
        console.log(
          EventState[ticketCountResponse.eventState] ===
            EventState["ACTIVE"].toString()
        );
        if (
          !(
            EventState[ticketCountResponse.eventState] ==
            EventState["ACTIVE"].toString()
          )
        ) {
          setTicketsAvailable("The Ticket Shop for this Event is closed.");
        } else {
          if (ticketCountResponse.hasTicketsAvailable) {
            setTicketsAvailable("true");
          } else {
            setTicketsAvailable("Tickets for this event are sold out.");
          }
        }
      })
      .catch((error) => {
        //TODO
      });
  }, []);
  const navigate = useNavigate();

  const setQuantity = (quantity: Quantity) => {
    setError("");
    setTickets((tickets) => {
      return tickets.map((ticket) => {
        if (ticket.id === quantity.id) {
          ticket.quantity = quantity.quantity;
        }
        return ticket;
      });
    });
  };

  const paypalbuttonTransactionProps: PayPalButtonsComponentProps = {
    style: { layout: "vertical", shape: "pill" },
    onClick: (data: Record<string, unknown>, actions: OnClickActions) =>
      onPaypalClick(data, actions),
    createOrder: (data: CreateOrderData, actions: CreateOrderActions) =>
      onCapture(data, actions),
    onApprove: (data, actions) => onApprove(data, actions),
    onCancel: (data: Record<string, unknown>, actions: OnCancelledActions) =>
      onCancel(data, actions),
  };

  function onCancel(
    data: Record<string, unknown>,
    actions: OnCancelledActions
  ) {
    console.log(data);
  }

  function onPaypalClick(
    data: Record<string, unknown>,
    actions: OnClickActions
  ) {
    console.log(hiddenEmailRef.current);
    let _email = emailRef.current;
    let _usePaypalEmail = useEmailRef.current;
    let paymentSource = data["fundingSource"];
    if (paymentSource === "paypal") {
      if (!_usePaypalEmail && !isValidEmail(_email)) {
        console.log("should reject");
        setError("The email address you entered is Invalid");
        return actions.reject();
      }
    }
    console.log("should resolve");
    return actions.resolve();
  }

  function onCapture(data: CreateOrderData, actions: CreateOrderActions) {
    let param = new URLSearchParams({
      //TODO: maybe its better if this is the event ID
      smartContractAddress: event.contractAddress,
      quantity: "" + tickets[0].quantity,
    });

    if (!useEmailRef.current && emailRef.current) {
      param.append("email", emailRef.current);
    }

    return fetch(
      process.env.REACT_APP_PILOT_BE_URL + "/v0/payment/createOrder?" + param,
      {
        method: "post",
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.status + ": " + response.statusText);
        } else {
          return response.json();
        }
      })
      .then((order) => {
        if (process.env.NODE_ENV === "development") {
          onApprove({ orderId: "9b31e519ed" }, {});
        }
        return order.id;
      })
      .catch((error: Error) => {
        setLoading(false);
        setError(error.message);
      });
  }

  function onFreeTicket() {
    setLoading(true);

    let param = new URLSearchParams({
      //TODO: maybe its better if this is the event ID
      smartContractAddress: event.contractAddress,
      quantity: "" + tickets[0].quantity,
    });
    console.log(email);
    param.append("email", email);
    return fetch(
      process.env.REACT_APP_PILOT_BE_URL +
        `/v0/payment/createFreeOrder?` +
        param,
      { method: "post" }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.status + ": " + response.statusText);
        } else {
          return response.json();
        }
      })
      .then((body) => {
        setSuccess(
          "Order has successfully been made and will be delivered by mail"
        );
        let _body = body as FreeOrderResponse;
        let orderInfo: OrderInfo = {
          verifyLinks: _body.verifyLinks,
          cvtIds: _body.cvtIds,
          quantity: tickets[0].quantity,
          price: tickets[0].price,
          currency: tickets[0].currency,
          ticketType: tickets[0].name,
          orderId: _body.orderId,
        };

        navigate("orderSummary", {
          state: orderInfo,
        });
      })
      .catch((error: Error) => {
        setLoading(false);
        console.log("error");
        setError(error.message);
      });
  }

  function onApprove(data: any, actions: any) {
    setLoading(true);
    return fetch(
      process.env.REACT_APP_PILOT_BE_URL +
        `/v0/payment/capture?orderId=${data.orderID}`,
      {
        method: "post",
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.status + ": " + response.statusText);
        } else {
          return response.json();
        }
      })
      .then((body) => {
        setSuccess(
          "Order has successfully been made and will be delivered by mail"
        );
        let _body = body as OrderResponse;
        let orderInfo: OrderInfo = {
          verifyLinks: _body.verifyLinks,
          cvtIds: _body.cvtIds,
          quantity: tickets[0].quantity,
          price: tickets[0].price,
          currency: tickets[0].currency,
          ticketType: tickets[0].name,
          orderId: data.orderID,
        };

        navigate("orderSummary", {
          state: orderInfo,
        });
      })
      .catch((error: Error) => {
        setLoading(false);
        console.log("error");
        setError(error.message);
      });
  }
  if (!process.env.REACT_APP_PAYPAL_CLIENT_ID) {
    return <LoadingScreen></LoadingScreen>;
  }
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <TicketTable
        tickets={tickets}
        setQuantity={setQuantity}
        currencyCode={event.currencyCode}
      ></TicketTable>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        {ticketsAvailable === "true" ? (
          <Box
            sx={{
              alignSelf: { xs: "center", md: "flex-end" },
              width: "100%",
              maxWidth: 400,
            }}
          >
            {event.price > 0 ? (
              <PayPalScriptProvider
                //TODO: enter for sandbox and production env
                options={{
                  "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                  currency: "EUR",
                  intent: "capture",
                  "disable-funding": "sofort,giropay,sepa,eps",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <FormControlLabel
                    sx={{ paddingY: 1, paddingX: 2 }}
                    labelPlacement="end"
                    control={
                      <Checkbox
                        checked={usePaypalEmail}
                        onChange={() => {
                          useEmailRef.current = !usePaypalEmail;
                          setUsePaypalEmail(!usePaypalEmail);
                        }}
                      />
                    }
                    label={"Use Email Address from PayPal"}
                  ></FormControlLabel>
                  {!usePaypalEmail ? (
                    <TextField
                      sx={{ paddingBottom: 2 }}
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      fullWidth={true}
                      value={email}
                      onChange={(event) => {
                        emailRef.current = event.target.value;
                        setEmail(event.target.value);
                      }}
                      disabled={usePaypalEmail}
                    />
                  ) : null}
                  {error ? (
                    <Typography color="red" mb={1}>
                      {error}
                    </Typography>
                  ) : null}
                  {!success ? (
                    <Typography color="red" mb={1}>
                      {success}
                    </Typography>
                  ) : null}
                  {loading ? <LoadingScreen></LoadingScreen> : null}
                </Box>
                <PayPalButtons {...paypalbuttonTransactionProps} />
              </PayPalScriptProvider>
            ) : (
              <Box sx={{ mb: 2 }}>
                <TextField
                  sx={{ paddingBottom: 2 }}
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  fullWidth={true}
                  value={email}
                  onChange={(event) => {
                    emailRef.current = event.target.value;
                    setEmail(event.target.value);
                  }}
                  disabled={usePaypalEmail}
                />
                <Button
                  fullWidth={true}
                  variant="contained"
                  onClick={() => onFreeTicket()}
                >
                  Generate Tickets
                </Button>
              </Box>
            )}
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              borderRadius: 5,
              padding: 1,
              justifyContent: "center",
              mb: 2,
            }}
          >
            <Typography alignSelf={"center"} variant="body1" color={"red"}>
              {ticketsAvailable}
            </Typography>
          </Box>
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <NFTDisplay></NFTDisplay>
      </Box>
    </Box>
  );
};

//{!hiddenEmail ? (
//<Box
//sx={{
//display: "flex",
//flexDirection: "column",
//}}
//>
//<TextField
//id="outlined-basic"
//label="Email"
//variant="outlined"
//fullWidth={true}
//value={email}
//onChange={(event) => {
//emailRef.current = event.target.value;
//setEmail(event.target.value);
//}}
//disabled={usePaypalEmail}
///>
//<FormControlLabel
//control={
//<Checkbox
//checked={usePaypalEmail}
//onChange={() => {
//useEmailRef.current = !usePaypalEmail;
//setUsePaypalEmail(!usePaypalEmail);
//}}
///>
//}
//label={"Use Email Address from PayPal"}
//></FormControlLabel>
//</Box>
//) : null}
