import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

export const NFTDisplay = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("lg"));

  if (isXs) {
    return (
      <Box
        sx={{
          display: "flex",
          alignSelf: { xs: "center", md: "flex-end" },
          width: "100%",
          maxWidth: 400,
          pb: 2,
        }}
        component={"img"}
        src="images/nft/vertical.png"
        alt="nft explanation"
      ></Box>
    );
  } else {
    return (
      <Box
        sx={{ display: "flex", width: { md: "100%", xl: "90%" }, pb: 2 }}
        component={"img"}
        src="images/nft/horizontal.png"
        alt="nft explanation"
      ></Box>
    );
  }
};
