import { Route, Routes } from "react-router-dom";
import { Frame } from "../shared:0.0.2/frame/Frame";
import { Share } from "../share/Share";
import Shop from "../shop/Shop";

type Props = { windowHeight: number };

export const RouteMapping = (props: Props) => {
  return (
    <Routes>
      <Route path="/share" element={<Share></Share>}></Route>
      <Route path="*" element={<Shop></Shop>}></Route>
    </Routes>
  );
};

export default RouteMapping;
