import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { footerColumns } from "../data/FooterColumns";
import { defaultTheme } from "../themes/defaultTheme";
import { Helmet } from "react-helmet";
import RouteMapping from "../../container/RouteMapping";
import { Footer } from "./components/Footer";
import Header from "./components/Header";
import CookieConsent, {
  getCookieConsentValue,
  resetCookieConsentValue,
} from "react-cookie-consent";
import { initHotJar } from "../../analytics/HotJar";
import { initGA } from "../../analytics/GA";
import { EventContext, EventContextProvider } from "./EventContextProvider";

export const Frame = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [cookies, setCookies] = useState<boolean | undefined>(
    getCookieConsentValue() ? getCookieConsentValue() === "true" : undefined
  );

  const { event, setEvent } = useContext(EventContext);
  useEffect(() => {
    const initializeAnalytics = () => {
      initHotJar();
      initGA();
    };
    if (cookies) {
      initializeAnalytics();
    }
  }, [cookies]);

  useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline></CssBaseline>
      {event ? (
        <Helmet>
          <title>{event.organizer}</title>
          <meta name="description" content={event.title} />
          <link
            rel="icon"
            href={
              process.env.REACT_APP_PILOT_BE_URL +
              "/static/" +
              event.contractAddress +
              "/meta/organizerImg.png"
            }
          />
        </Helmet>
      ) : null}
      <EventContextProvider>
        <Box sx={{ background: "background.default" }}>
          <Box sx={{ minHeight: windowHeight }}>
            <Header></Header>
            <BrowserRouter>
              <RouteMapping windowHeight={windowHeight}></RouteMapping>
            </BrowserRouter>
            <CookieConsent
              enableDeclineButton
              onDecline={() => {}}
              onAccept={() => {
                setCookies(true);
              }}
            >
              This website uses Cookies to enhance your user Experience!
            </CookieConsent>
          </Box>
          <Footer></Footer>
        </Box>
      </EventContextProvider>
    </ThemeProvider>
  );
};
