import { IosShare } from "@mui/icons-material";
import { Button, Container, Typography } from "@mui/material";

export const Share = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  let smartContractAddress = params.get("smartContractAddress");
  let cvtId = params.get("cvtId");
  return (
    <Container maxWidth="xs">
      <Typography variant="h6" style={{ fontWeight: "bold", marginTop: 20 }}>
        Ticket Artwork #{cvtId}
      </Typography>
      <img
        src={
          process.env.REACT_APP_STATIC_FILES_SERVER +
          "/" +
          smartContractAddress +
          "/images/" +
          cvtId +
          ".png"
        }
        alt="artwork-image"
        style={{
          width: "100%",
          height: "100%",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        }}
      />
      <Button
        variant="contained"
        fullWidth={true}
        style={{
          height: 55,
          marginTop: -10,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
        }}
        onClick={() => {
          fetch(
            process.env.REACT_APP_STATIC_FILES_SERVER +
              "/" +
              smartContractAddress +
              "/images/" +
              cvtId +
              ".png",
            {
              method: "GET",
            }
          )
            .then((response) => response.blob())
            .then((blob) => {
              let file = new File([blob], "artwork.png");
              if (navigator.share) {
                navigator.share({ files: [file] });
              } else {
                const url =
                  process.env.REACT_APP_STATIC_FILES_SERVER +
                  "/" +
                  smartContractAddress +
                  "/images/" +
                  cvtId +
                  ".png";
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "artwork.png");
                document.body.appendChild(link);
                link.click();
              }
            });
        }}
      >
        <IosShare></IosShare>
        <Typography ml={1}>Continue</Typography>
      </Button>
    </Container>
  );
};
