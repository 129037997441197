import {
  Box,
  ButtonBase,
  Grid,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { createContext, useContext, useState } from "react";
import { EventData } from "../../types/dto";
import { handleGAShare } from "../../analytics/GA";
import { footerColumns } from "../../data/FooterColumns";
import { blackTheme } from "../../themes/blackTheme";
import { IconLink, IconLinkColumn } from "../../types/bll";
import IconSwitcher from "./IconSwitcher";
import { EventContext } from "../EventContextProvider";

export type FooterContextType = {
  columns: IconLinkColumn[];
  partnerLogoUrl: string | null;
};

export const Footer = () => {
  const { event, setEvent } = useContext(EventContext);
  const [footer, setFooter] = useState<FooterContextType>({
    columns: footerColumns,
    partnerLogoUrl: null,
  });

  React.useEffect(() => {
    if (event && footer.columns.length === 3) {
      let newColumns = footer.columns;
      newColumns.push({
        name: event.organizer,
        items: event.socials.map((social) => {
          return {
            name: social.name,
            icon: social.name,
            link: social.link,
          } as IconLink;
        }),
      });
      let newFooter = {
        columns: newColumns,
        partnerLogoUrl:
          process.env.REACT_APP_PILOT_BE_URL +
          "/static/" +
          event.contractAddress +
          "/meta/organizerImg.png",
      };
      setFooter(newFooter);
    }
  }, [event]);

  const getIconElement = (iconLink: IconLink, key: number) => {
    return (
      <ButtonBase
        key={key}
        onClick={() => {
          handleGAShare("User", "Footer " + iconLink.name, iconLink.link);
          window.open(iconLink.link, "_blank");
        }}
        sx={{ mb: 1, display: "flex", flexDirection: "row" }}
      >
        <IconSwitcher name={iconLink.icon}></IconSwitcher>
        <Typography marginLeft={2}>{iconLink.name}</Typography>
      </ButtonBase>
    );
  };

  return (
    <ThemeProvider theme={blackTheme}>
      <Box
        sx={{
          padding: 3,
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
          backgroundColor: "background.default",
        }}
      >
        <Box
          sx={{
            flex: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
            alignItems: "center",
          }}
        >
          <Box sx={{ p: 1, flex: 1 }}>
            <img
              src="/images/logos/mh_logo_medium_white.png"
              alt="mg_logo"
              style={{
                width: "100%",
                maxWidth: 150,
                height: "auto",
                objectFit: "contain",
              }}
            ></img>
          </Box>
          {footer.partnerLogoUrl ? (
            <Typography alignSelf={"center"} variant="h2">
              X
            </Typography>
          ) : null}
          {footer.partnerLogoUrl ? (
            <Box sx={{ p: 1, display: "flex", flexDirection: "row", flex: 1 }}>
              <img
                src={footer.partnerLogoUrl}
                alt="mg_logo"
                style={{ width: "100%", height: "auto", objectFit: "contain" }}
              ></img>
            </Box>
          ) : null}
        </Box>
        <Grid container spacing={2} sx={{ flex: 5 }}>
          {footer.columns.map((c, key) => (
            <Grid item key={key} xs={6} sm={4} md={3}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                }}
              >
                <Typography fontWeight="bold" marginBottom={1} sx={{ ml: 2 }}>
                  {c.name}
                </Typography>
                <Box>{c.items.map((i, key) => getIconElement(i, key))}</Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </ThemeProvider>
  );
};
