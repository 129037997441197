import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { EventData } from "../../shared:0.0.2/types/dto";
import { EventContext } from "../../shared:0.0.2/frame/EventContextProvider";

type Props = {
  event: EventData;
};

export const Information = ({ event }: Props) => {
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h2">{event.title}</Typography>
      {event.infos.map((info, key) => {
        return (
          <Typography key={key} variant="h6">
            {info}
          </Typography>
        );
      })}
      <Typography>More Info</Typography>
      <Typography style={{ whiteSpace: "pre-wrap" }}>
        {event.description}
      </Typography>
    </Box>
  );
};
