import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Quantity, Ticket } from "../../types";
import { Currency } from "../../shared:0.0.2/types/dto";
import { TicketCell } from "./TicketCell";

type Props = {
  tickets: Ticket[];
  setQuantity: (quantity: Quantity) => void;
  currencyCode: Currency;
};

export const TicketTable = ({ currencyCode, tickets, setQuantity }: Props) => {
  let currChar = getCurrencyCharacter(currencyCode);

  function getCurrencyCharacter(currencyCode: Currency) {
    if (currencyCode === "EUR") {
      return "€";
    } else if (currencyCode === "CHF") {
      return "SFr.";
    }
    return "";
  }

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <TableContainer sx={{ display: "flex", flexDirection: "column" }}>
      <Table aria-label="simple table">
        <TableHead key={"table-head"}>
          <TableRow key="header-row">
            <TableCell key="name_row"></TableCell>
            <TableCell key={"single_price"} align="right">
              <Typography fontWeight={"bold"}>Single Price&nbsp;</Typography>
            </TableCell>
            <TableCell
              key={"quantity"}
              sx={{ fontWeight: "bold" }}
              align="right"
            >
              <Typography fontWeight={"bold"}>Quantity&nbsp;</Typography>
            </TableCell>
            {!isXs ? (
              <TableCell
                key={"total"}
                sx={{ fontWeight: "bold" }}
                align="right"
              >
                <Typography fontWeight={"bold"}>Total Price&nbsp;</Typography>
              </TableCell>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody key={"table-body"}>
          {tickets.map((ticket) => (
            <TicketCell
              currChar={currChar}
              key={ticket.id}
              ticket={ticket}
              setQuantity={setQuantity}
            />
          ))}
          <TableRow
            key={"total"}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell key={"emtpy1"} component="th" scope="row"></TableCell>
            <TableCell key={"sum-total-name"} align="right">
              <Typography>{"Total"}</Typography>
            </TableCell>
            {!isXs ? (
              <TableCell key={"emtpy2"} align="right"></TableCell>
            ) : null}
            <TableCell key={"sum-total"} align="right">
              <Typography>
                {tickets.reduce((acc, curr) => {
                  return acc + curr.price * curr.quantity;
                }, 0) + currChar}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Divider></Divider>
      <Typography variant="body2" sx={{ my: 2, mb: 10, alignSelf: "flex-end" }}>
        All prices include VAT
      </Typography>
      <Divider sx={{ mb: 2 }}></Divider>
    </TableContainer>
  );
};
