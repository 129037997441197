import { ConfirmationNumber, Info, Token } from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Information } from "./components/Information";
import { TicketShop } from "./components/TicketShop";

import { NFTDisplay } from "./components/NFTDisplay";
import { EventData } from "../shared:0.0.2/types/dto";

enum Section {
  TICKETS = "Buy Tickets",
  INFO = "Event Information",
  NFT = "NFT Tickets",
}

type Props = {
  event: EventData;
};

export const ProductDisplay = ({ event }: Props) => {
  const [activeSection, setActiveSection] = React.useState<Section>(
    Section.TICKETS
  );

  //TODO: dynamic resizing of height

  const getSection = () => {
    switch (activeSection) {
      case Section.TICKETS:
        return <TicketShop event={event} />;
      case Section.INFO:
        return <Information event={event} />;
      case Section.NFT:
        return (
          <Box
            sx={{ display: "flex", flex: 1, p: 2, justifyContent: "center" }}
          >
            <NFTDisplay></NFTDisplay>{" "}
          </Box>
        );
    }
  };

  const getSectionButton = (section: Section, icon: JSX.Element) => {
    let isActive = activeSection === section;
    return (
      <Box>
        <Button
          variant={isActive ? "contained" : "text"}
          onClick={() => setActiveSection(section)}
          sx={{
            mr: 2,
            backgroundColor: (isActive && "white") || "transparent",
            textTransform: "none",
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
          >
            <Box
              sx={{
                display: "flex",
                borderRadius: 1,
                width: 24,
                height: 24,
                mr: 1,
                alignSelf: "center",
              }}
            >
              {icon}
            </Box>
            <Typography color={"text.secondary"}>
              {section.toString()}
            </Typography>
          </Box>
        </Button>
        {isActive ? (
          <Divider
            sx={{
              mt: 2,
              backgroundColor: isActive ? "primary.main" : undefined,
            }}
          ></Divider>
        ) : null}
      </Box>
    );
  };

  return (
    <Box sx={{ height: "100%" }}>
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          justifyContent: { xs: "space-between", xl: "flex-start" },
        }}
      >
        {getSectionButton(
          Section.TICKETS,
          <ConfirmationNumber sx={{ p: 0.2 }}></ConfirmationNumber>
        )}
        {getSectionButton(Section.INFO, <Info sx={{ p: 0.2 }}></Info>)}
        {getSectionButton(Section.NFT, <Token sx={{ p: 0.2 }}></Token>)}
      </Box>{" "}
      <Divider></Divider>
      {getSection()}
    </Box>
  );
};
