import { Box, Container, Typography } from "@mui/material";
import React from "react";

import { Route, Routes } from "react-router-dom";
import { EventContext } from "../shared:0.0.2/frame/EventContextProvider";
import { EventData, EventState } from "../shared:0.0.2/types/dto";
import LoadingScreen from "../util/LoadingScreen";
import { OrderSummary } from "./OrderSummary";
import { ProductDisplay } from "./ProductDisplay";

const Shop = () => {
  const { event, setEvent } = React.useContext(EventContext);

  const [screenSize, getDimension] = React.useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  React.useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  React.useEffect(() => {
    let eventId = window.location.search.slice(9);
    fetch(process.env.REACT_APP_PILOT_BE_URL + "/v0/event?eventId=" + eventId)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.status + ": " + response.statusText);
        } else {
          return response.json();
        }
      })
      .then((_eventData: EventData) => {
        if (_eventData.eventState === EventState.NONE) {
          throw new Error("404" + ": " + "Event not available!");
        }
        setEvent(_eventData);
      })
      .catch((error) => {
        //TODO: enter meaningful something
      });
  }, []);

  if (!event) {
    return <LoadingScreen></LoadingScreen>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "secondary.main",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            height: screenSize.dynamicHeight - 30,
            flexDirection: "column",
            backgroundColor: "background.default",
            justifyContent: "space-around",
          }}
        >
          <Container
            sx={{
              p: { xs: 5, md: 5, xl: 10 },
              ph: { xs: 2, md: 5 },
              display: "flex",
              flex: 1,
              flexDirection: "column",
              backgroundColor: "secondary.main",
              borderTopRightRadius: { xs: 0, md: 20 },
              justifyContent: "space-around",
            }}
          >
            <Box
              flexDirection={"column"}
              display="flex"
              sx={{
                width: "80%",
                backgroundColor: "background.default",
                boxShadow: 10,
                borderRadius: 2,
              }}
              alignSelf="center"
              overflow="hidden"
            >
              <Box
                display="flex"
                sx={{ overflow: "hidden" }}
                width="100%"
                component="img"
                src={
                  process.env.REACT_APP_PILOT_BE_URL +
                  "/static/" +
                  event.contractAddress +
                  "/meta/poster.png"
                }
                alt="ticketImage"
                alignSelf="center"
              ></Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  p: 2,
                }}
              >
                <Typography variant="h5" pb={1}>
                  {event.title}
                </Typography>
                {event.infos.map((info, key) => (
                  <Typography key={key}>{info}</Typography>
                ))}
              </Box>
            </Box>
          </Container>
        </Box>
        <Container
          sx={{
            overflow: "auto",
            display: "flex",
            flex: 1.5,
            flexDirection: "column",
            py: { xs: 2, md: 0 },
            borderRadius: { xs: 5, md: 0 },
            borderBottomLeftRadius: { md: 20 },
            backgroundColor: "background.default",
          }}
        >
          <Routes>
            <Route
              path="*"
              element={<ProductDisplay event={event}></ProductDisplay>}
            ></Route>
            <Route
              path="orderSummary"
              element={<OrderSummary></OrderSummary>}
            ></Route>
          </Routes>
        </Container>
      </Box>
    </Box>
  );
};

export default Shop;
